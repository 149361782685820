import { useSelector } from 'react-redux'
import {
  selectClient,
  selectHasAuthorized,
  selectIsApiOnly,
  setHasAuthorized,
} from 'src/features/auth/authSlice'
import { useAppDispatch } from 'src/redux/store'
import { useLocation } from '@reach/router'

/**
 * Hook utilized to determine if the user should be prompted with the `Authorize` screen.
 */
const useAuthorize = (): { isAuthorizeRequired: boolean } => {
  const dispatch = useAppDispatch()
  const client = useSelector(selectClient)
  const hasAuthorized = useSelector(selectHasAuthorized)
  const isApiOnly = useSelector(selectIsApiOnly)
  const location = useLocation()

  // Check if we should bypass the `Authorize` screen if the client is API only.
  if (isApiOnly && location.pathname !== '/login/oauth/authorize') {
    dispatch(setHasAuthorized(true))
    return { isAuthorizeRequired: false }
  }

  // If not bypassing, check if we have a client ID and if authorization is needed
  const hasClientId = Boolean(client.clientId)
  const needsAuthorization = !hasAuthorized

  return { isAuthorizeRequired: hasClientId && needsAuthorization }
}

export default useAuthorize
